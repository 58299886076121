.flexBlock{
    display: flex;
    justify-content: center;
}
.form{
    width: 500px;
    height: 700px;
    background-color: #413F56;
    margin-top: 30px;
}
.input{
    border: none;
    border-bottom: 4px solid #958CFF;
    background-color: transparent;
    outline: none;
    color: #DADADA;
    font-size: 20px;
    width: 385px;
    padding: 5px;
    display: block;
    margin: 5px auto;
}
.textInput{
    color: #FFFFFF;
    font-size: 24px;
    display: block;
    padding: 30px 0 0 55px;
    font-weight: 600;
}
#indent{
    padding-top: 100px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
}
.tabDirectionLine{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.tabDirection{
    width: 190px;
    height: 60px;
    border: 0;
    background-color: #D9D9D9;
}
#tabDirectionLeft{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
#tabDirectionRight{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.inputSwitch{
    background-color: #D9D9D9;
    border: none;
    font-size: 18px;
    margin: 5px 0 0 12px;
    height: 50px;
    width: 160px;
    cursor: pointer;
    outline:none;
    caret-color: transparent;
    resize: none;
    transition: 0.3s;
}
#call{
    background-image: url("background.jpg");
    width: 500px;
    height: 700px;
    margin-top: 30px;
    background-size: cover;
    display: flex;
    justify-content: center;
}
#callText{
    padding-top: 150px;
}
.nameDate{
    font-family: "Exo 2", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    color: #3e3e3e;
    /*padding-left: 25px;*/
    text-align: center;
}
.name {
    font-family: "Exo 2", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-size: 55px;
    color: #885a40;
    padding-left: 25px;
    text-align: center;
}
.callMassage{
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    color: #353535;
    padding: 12px 0 0 70px;
}
.start{
    font-weight: 1000;
    font-style: italic;
    font-size: 100px;
    color: #885a40;
    text-align: center;
    text-shadow: 1px 1px 1px black;
    padding-left: 25px;
    margin-top: -10px;
}
/*#birth {*/
/*    position: relative;*/
/*    top: -130px;*/
/*}*/
#birthday {
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 20px 70px 70px;
    height: 150px;
    width: 250px;
    background-image: url("birthday.png");
}
/*#day{*/
/*    font-weight: 1000;*/
/*    font-size: 90px;*/
/*    text-align: center;*/
/*    padding-left: 25px;*/
/*    color: transparent;*/
/*    !*-webkit-text-stroke: 6px #a68202;*!*/
/*    -webkit-background-clip: text;*/
/*    background-clip: text;*/
/*    background-image: linear-gradient(45deg, #3c1704, #fbc2a7);*/
/*    position: relative;*/
/*    top: 40px;*/
/*}*/
#place{
    position: relative;
    top: -80px;
    padding-left: 91px;
}
#nameDate{
    position: relative;
    top: -55px;
    font-weight: 1000;
    padding-left: 25px;
}
.start{
    font-size: 35px;
    font-style: normal;
    text-shadow: none;
    position: relative;
    top: -50px;
}
#startTime{
    top: -50px;
}
.address{
    padding-left: 120px;
    position: relative;
    top: -60px;
    font-weight: 800;
    font-size: 22px;
}
#addressId{
    top: -75px;
    text-align: center;
    padding-left: 80px;
    width: 200px;
}
.downlbut {
    background-color: #6E69A3;
    width: 185px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
    border: #413F56 2px solid;
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    color: white;
}
.downlbut:hover {
    background-color:  #413F56;
    border: #6E69A3 2px solid;
}
.buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 20px;
    justify-content: center;

}
@media screen and (max-width: 1100px) {
    .name {
        font-size: 65px;
        padding: 0 0 0 50px;
    }
    .form{
        width: 350px;
        height: 600px;
    }
    .input{
        border-bottom: 3px solid #958CFF;
        font-size: 16px;
        width: 270px;
    }
    .textInput{
        font-size: 20px;
        padding: 25px 0 0 40px;
        font-weight: 500;
    }
    #indent{
        padding-top: 70px;
    }
    .inputSwitch{
        font-size: 14px;
        margin: 5px 0 0 10px;
        height: 40px;
        width: 120px;
    }
    .tabDirection{
        width: 140px;
        height: 50px;
    }
    #call{
        width: 600px;
        height: 800px;
    }
    .nameDate{
        font-size: 22px;
        padding-left: 20px;
    }
    .callMassage{
        font-size: 24px;
        padding: 8px 0 0 60px;
    }
    .start{
        font-size: 100px;
    }

    /*#day{*/
    /*    font-size: 90px;*/
    /*    top: 35px;*/

    /*}*/
    #place{
        font-size: 24px;
        top: -85px;
        padding-left: 90px;
    }
    #nameDate{
        top: -75px;
        padding-left: 50px;
        font-size: 32px;
    }
    .start{
        font-size: 48px;
        top: -65px;
        padding-left: 50px;
    }
    #startTime{
        top: -75px;
    }
    .address{
        padding-left: 130px;
        top: -90px;
    }
    #addressId{
        top: -100px;
        padding-left: 115px;
        width: 160px;
    }
    .downlbut {
        width: 135px;
        height: 50px;
        font-size: 14px;
    }
    #callText {
        padding-top: 130px;
    }

}
@media screen and (max-width: 800px) {
    .name {
        font-size: 40px;
    }
    .form{
        width: 250px;
        height: 450px;
    }
    .input{
        border-bottom: 3px solid #958CFF;
        font-size: 14px;
        width: 190px;
    }
    .textInput{
        font-size: 16px;
        padding: 18px 0 0 30px;
    }
    #indent{
        padding-top: 40px;
    }
    .inputSwitch{
        font-size: 10px;
        margin: 5px 0 0 10px;
        height: 28px;
        width: 84px;
    }
    .tabDirection{
        width: 105px;
        height: 40px;
    }
    #call{
        width: 100%;
        height: 550px;
    }
    #callText{
        display: flex;
        width: 100%;
        padding-top: 90px;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
    .nameDate{
        font-size: 20px;
        padding-left: 8px;
    }
    .callMassage{
        font-size: 14px;
        padding-left: 30px;
    }
    .start{
        font-size: 50px;
    }
    /*#birth {*/
    /*    font-size: 70px;*/
    /*    position: relative;*/
    /*    top: -70px;*/
    /*    font-weight: 1000;*/
    /*}*/
    /*#day{*/
    /*    font-size: 60px;*/
    /*    top: 45px;*/
    /*    font-weight: 1000;*/
    /*}*/
    #place{
        top: -40px;
        padding-left: 30px;
    }
    #nameDate{
        top: -40px;
        font-size: 18px;
    }
    .start{
        font-size: 26px;
        top: -40px;
    }
    #startTime{
        top: -40px;
    }
    .address{
        padding-left: 35px;
        top: -45px;
    }
    #addressId{
        top: -50px;
        padding-left: 35px;
    }
    .downlbut {
        width: 100px;
        height: 40px;
        font-size: 12px;
    }
    #birthday {
        width: 150px;
        height: 100px;
    }
}
@media screen and (max-device-width: 800px) {
    .flexBlock{
        display: block;
    }
    .form{
        width: 100%;
        height: 550px;
        margin-top: 0;
    }
    .input{
        border-bottom: 3px solid #958CFF;
        font-size: 18px;
        width: 80%;
    }
    .textInput{
        font-size: 20px;
        padding: 18px 0 0 35px;
    }
    #indent{
        padding-top: 40px;
    }
    .inputSwitch{
        font-size: 16px;
        margin: 10px 0 0 15px;
        height: 40px;
        width: 140px;
    }
    .tabDirection{
        width: 160px;
        height: 60px;
    }
    #call{
        width: 100%;
        height: 600px;
        margin: 0;
    }
    #callText{
        padding-top: 80px;
    }
    .nameDate{
        font-size: 20px;
        padding-left: 8px;
    }
    .callMassage{
        font-size: 16px;
        padding: 8px 0 0 35px;
    }
    .start{
        font-size: 70px;
    }
    /*#day{*/
    /*    font-size: 80px;*/
    /*    top: -45px;*/
    /*    -webkit-text-stroke: 3px #ff6f00;*/
    /*}*/
    .name {
        margin-left: 10px;
    }
    #birthday {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 170px;
        height: 100px;
        /*margin-left: 30px;*/
        /*margin-top: 10px;*/
        margin: 10px 0px 40px 40px;
    }
    /*img {*/
    /*    width: 240px;*/
    /*    height: 160px;*/
    /*}*/
    #place{
        top: -45px;
        /*margin-left: -80px;*/
        font-size: 18px;
    }
    #nameDate{
        top: -35px;
        font-size: 18px;
    }
    .start{
        font-size: 30px;
        top: -25px;
    }
    #startTime{
        top: -25px;
    }
    .address{
        padding-left: 30px;
        top: -35px;
    }
    #addressId{
        top: -45px;
        padding-left: 45px;
    }
    .downlbut {
        width: 155px;
        height: 60px;
        font-size: 16px;
    }
}
@media screen  and (max-device-width: 1500px) and (orientation: landscape) {
    .flexBlock{
        display: block;
        /*justify-content: center;*/
    }
    .form{
        width: 100%;
        height: 550px;
        margin-top: 0;
    }
    .input{
        border-bottom: 3px solid #958CFF;
        font-size: 18px;
        width: 80%;
    }
    .textInput{
        font-size: 20px;
        padding: 18px 0 0 70px;
    }
    #indent{
        padding-top: 40px;
    }
    .inputSwitch{
        font-size: 16px;
        margin: 10px 0 0 15px;
        height: 40px;
        width: 140px;
    }
    .tabDirection{
        width: 260px;
        height: 60px;
    }
    .name {
        margin-left: 60px;
        margin-top: 40px;
        font-size: 120px;
    }
    #call{
        width: 100%;
        height: 1700px;
        margin: 0;
    }
    #callText{
        padding-top: 250px;
    }
    .nameDate{
        font-size: 20px;
        padding-left: 8px;
    }
    .callMassage{
        font-size: 50px;
        padding: 8px 0 20px 110px;
    }
    .start{
        font-size: 70px;
    }
    /*#day{*/
    /*    font-size: 80px;*/
    /*    top: -45px;*/
    /*    -webkit-text-stroke: 3px #ff6f00;*/
    /*}*/
    #birthday {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 600px;
        height: 340px;
        margin-left: 100px;
        margin-top: 10px;
        background-image: url("birthday.png");
    }

    /*img {*/
    /*    width: 240px;*/
    /*    height: 160px;*/
    /*}*/
    #place{
        top: -45px;
        margin-left: 90px;
        font-size: 50px;
    }
    #nameDate{
        top: -45px;
        font-size: 70px;
        margin-left: 40px;
    }
    .start{
        font-size: 90px;
        top: -40px;
        margin-left: 40px;
    }
    #startTime{
        top: -35px;
    }
    .address{
        padding-left: 270px;
        top: -45px;
        font-size: 50px;
    }
    #addressId{
        top: -50px;
        padding-left: 200px;
        font-size: 50px;
        width: 400px;
    }
    .downlbut {
        width: 255px;
        height: 60px;
        font-size: 16px;
    }
    /*#call{*/
    /*    width: 100%;*/
    /*    height: 850px;*/
    /*    margin: 0;*/
    /*}*/
    /*img {*/
    /*    width: 240px;*/
    /*    height: 160px;*/
    /*}*/
    /*.birthday {*/
    /*    margin-left: 45px;*/
    /*}*/
    /*.start {*/
    /*    top: -5px;*/

    /*}*/
    /*#startTime {*/
    /*    top: -5px;*/
    /*}*/
    /*.address {*/
    /*    margin-left: -5px;*/
    /*    top: -10px;*/
    /*}*/
    /*#addressId {*/
    /*    margin-left: -50px;*/
    /*    top: -15px;*/
    /*}*/
    /*.callMassage {*/
    /*    padding-left: 40px;*/
    /*}*/
    /*#place {*/
    /*    top: -10px;*/
    /*    padding-left: 95px;*/
    /*}*/
    /*#nameDate {*/
    /*    top: -10px;*/
    /*}*/
}
